import { api } from '@rd-web-markets/shared/dist/services/service';
const generalService = {
  get: function (id, route, subRoute) {
    let subRouteId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    return api.get("/".concat(route, "/").concat(id, "/").concat(subRoute, "/").concat(subRouteId));
  },
  update: function (id, route, subRoute, body) {
    let subRouteId = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
    return api.update("/".concat(route, "/").concat(id, "/").concat(subRoute, "/").concat(subRouteId), body);
  },
  delete: (id, route, subRoute, subRouteId) => api.delete("/".concat(route, "/").concat(id, "/").concat(subRoute, "/").concat(subRouteId)),
  create: (id, route, subRoute, body) => api.create("/".concat(route, "/").concat(id, "/").concat(subRoute), body),
  putFormData: function (id, route, subRoute, body) {
    let subRouteId = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
    return api.putFormData("/".concat(route, "/").concat(id, "/").concat(subRoute, "/").concat(subRouteId), body);
  },
  postFormData: (id, route, subRoute, body) => api.postFormData("/".concat(route, "/").concat(id, "/").concat(subRoute), body)
};
export default generalService;