import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const projectContractorsService = {
  all(reportId) {
    let contractor_type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'bfsz';
    return fetch("/api/claim_project_reports/".concat(reportId, "/project_contractors?contractor_type=").concat(contractor_type), request.get);
  },
  update(reportId, project_contractor) {
    return fetch("/api/claim_project_reports/".concat(reportId, "/project_contractors/").concat(project_contractor.id), {
      ...request.put,
      body: JSON.stringify({
        project_contractor
      })
    });
  },
  create(reportId, project_contractor) {
    return fetch("/api/claim_project_reports/".concat(reportId, "/project_contractors/"), {
      ...request.post,
      body: JSON.stringify({
        project_contractor
      })
    });
  },
  delete(reportId, project_contractor_id) {
    return fetch("/api/claim_project_reports/".concat(reportId, "/project_contractors/").concat(project_contractor_id), {
      ...request.delete
    });
  },
  get(reportId, contractor_id) {
    return fetch("/api/claim_project_reports/".concat(reportId, "/project_contractors/").concat(contractor_id), request.get);
  }
};
export default projectContractorsService;