import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const businessUnitsService = {
  create(companyId, business_unit) {
    return fetch("/api/companies/".concat(companyId, "/business_units"), {
      ...request.post,
      body: JSON.stringify({
        business_unit
      })
    });
  },
  delete(companyId, business_unit_id) {
    return fetch("/api/companies/".concat(companyId, "/business_units/").concat(business_unit_id), {
      ...request.delete
    });
  },
  update(companyId, business_unit) {
    return fetch("/api/companies/".concat(companyId, "/business_units/").concat(business_unit.id), {
      ...request.put,
      body: JSON.stringify({
        business_unit
      })
    });
  }
};
export default businessUnitsService;