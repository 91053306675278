import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const firstFastChecksService = {
  get(reportId) {
    return fetch("/api/claim_project_reports/".concat(reportId, "/first_fast_check"), request.get);
  },
  update(first_fast_check) {
    return fetch("/api/claim_project_reports/".concat(first_fast_check.claim_project_report_id, "/first_fast_check"), {
      ...request.put,
      body: JSON.stringify({
        first_fast_check
      })
    });
  }
};
export default firstFastChecksService;