import { Breadcrumb } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';

export default function AllBaseInformationBreadcrumb({claimGroup}) {
  const user = useSelector((state) => state.auth.user);
  return (
    <BreadcrumbsPortal>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}`}}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies`}}>Companies</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies/${claimGroup.company_id}/master`}}>{claimGroup.company.name}</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/companies/${claimGroup.company_id}/${claimGroup.id}/master`}}>{claimGroup.name}</Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsPortal>
  );
}
