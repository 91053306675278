import Modal from '@rd-web-markets/shared/dist/util/Modal';
import React, { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap';

import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '@rd-web-markets/shared/dist/util/Loader';
import claimProjectReportSliceService from '@services/claim_groups/project_reports/claim_project_report_slice.service';
import { getDefaultConfig } from '@rd-web-markets/shared/dist/util/tinyMceConfig';
import debounce from 'lodash.debounce';
import { useMemo } from 'react';
import ReviewableFieldEditor from '@rd-web-markets/shared/dist/util/textEdit/ReviewableFieldEditor';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';

export default function EditProjectReportSliceModal({
  onHide,
  onContentChanged,
  isVisible,
  title,
  projectId = null,
  sliceId = null,
  claimGroupId = null,
  isUnderReview=false,
  footnoteStartIndex = 0
}) {
  const [projectReportSlice, setProjectReportSlice] = useState(null);
  const [editText, setEditText] = useState({});
  const [apiUpdateResult, setApiUpdateResult] = useState({});
  const [highlightsText, setHighlightsText] = useState(null);
  const [editableTitle, setEditableTitle] = useState(null);
  const [textLoaded, setTextLoaded] = useState(false);
  const [closeButtonLoading, setCloseButtonLoading] = useState(false);
  const [reportTextWasChanged, setReportTextWasChanged] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user)
  const showTrackChangesControls = user.account_type !== 'customer'
  const [claimGroup, setClaimGroup] = useState(null)

  /**
   * 
   * @param {*} editText always provided
   * @param {*} highlightText always provided
   * @param {*} editableTitle only if editing a project report slice
   * @param {*} projectReportSlice 
   * @param {*} rndReportTemplate 
   */
  const setEditData = useCallback(
    (
      newEditText, newHighlightText, newEditableTitle = null, newProjectReportSlice = null
    ) => {
      setProjectReportSlice({ ...newProjectReportSlice })

      setEditText(newEditText)
      setHighlightsText(newHighlightText)
      setEditableTitle(newEditableTitle)
    }, 
    []
  )

  useEffect(() => {
    claimGroupService.get(claimGroupId).then(setClaimGroup)
  }, [claimGroupId])

  useEffect(() => {
    const setText = async () => {
      try {

        const projectReportSlice = await claimProjectReportSliceService.get(projectId, sliceId)
        const editText = {
          content: projectReportSlice.body,
          timeOfUpdate: null
        }

        setEditData(editText, projectReportSlice.body, projectReportSlice.label, { ...projectReportSlice })
        setTextLoaded(true);
      } catch (error) {
        dispatch(handleError(error))
      }
    };

    setText();
  }, [claimGroupId, dispatch, projectId, setEditData, sliceId]);

  const debouncedUpdateReportText = useMemo(
    () => {
      setReportTextWasChanged(true)
      const updateProjectReportSlice = async (title, text, timeOfUpdate) => {
        const updatedSlice = await claimProjectReportSliceService.update(
          projectId, {
          ...projectReportSlice,
          label: title,
          body: text
        })

        setApiUpdateResult({
          editDataParams: [
            updatedSlice.body, 
            updatedSlice.body, 
            updatedSlice.label, 
            { ...updatedSlice }
          ],
          timeOfUpdate
        })
      };

      return debounce(async (title, text, timeOfUpdate) => {
        try {
          await updateProjectReportSlice(title, text, timeOfUpdate);
          setCloseButtonLoading(false);
        } catch (error) {
          console.log(error)
          dispatch(handleError(error))
        }
      }, 1000)
    }, 
    [projectId, projectReportSlice, dispatch]
  )

  useEffect(() => {
    if (editText.timeOfUpdate) {
      if (editText.timeOfUpdate > apiUpdateResult.timeOfUpdate || !apiUpdateResult.timeOfUpdate) {
        debouncedUpdateReportText(editableTitle, editText.content, editText.timeOfUpdate)
      } else if (apiUpdateResult.timeOfUpdate && apiUpdateResult.timeOfUpdate >= editText.timeOfUpdate) {
        setEditData(...apiUpdateResult.editDataParams)
      }
    }
  }, [apiUpdateResult, debouncedUpdateReportText, editText, editableTitle, setEditData])

  const editProjectReportSliceModalBody = () => {
    if (!textLoaded) {
      return <Loader />
    }

    return (
      <Card>
        <Card.Body>
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="forProjectSliceTitle">
              <Form.Label column sm="2">
                  Title
              </Form.Label>
              <Col sm="10">
                <Form.Control 
                  title="edit project section title" 
                  value={editableTitle} 
                  onChange={e => {
                    setEditableTitle(e.target.value)
                    debouncedUpdateReportText(e.target.value, editText.content, Date.now())
                  }} />
              </Col>
            </Form.Group>
          </Form>

          { claimGroup && user.account_type === 'customer' && claimGroup.active_change_set.finalized_at &&
            <div dangerouslySetInnerHTML={{ __html: editText.content }}></div>
          }

          { claimGroup && !(user.account_type === 'customer' && claimGroup.active_change_set.finalized_at) &&
            <ReviewableFieldEditor
              isUnderReview={isUnderReview}
              editorContent={editText.content}
              modelClass='ProjectReportSlice'
              modelId={sliceId}
              modelField={'body'}
              editorInitConfig={
                getDefaultConfig({
                  footnoteStartIndex,
                  stylesFormatterToggleIniitialValue: true,
                  modules: { styles_formatter: true }
                })
              }
              trackChangesOnCreateComment={content => {
                setCloseButtonLoading(true);
                const timeOfUpdate = Date.now()
                setEditText({ content, timeOfUpdate })
              }}
              trackChangesEditoInitFn={
                addCommentCallback => {
                  const editorModuleOptions = {
                    isTrackingChanges: true,
                    onAddCommentButtonClickCallback: addCommentCallback,
                    showTrackChangesControls: showTrackChangesControls,
                    footnoteStartIndex
                  }

                  return getDefaultConfig(editorModuleOptions)
                }
              }
              onEditorChange={content => {
                setCloseButtonLoading(true);
                const timeOfUpdate = Date.now()
                setEditText({ content, timeOfUpdate })
              }}
              showTrackChangesControls={showTrackChangesControls}
            />
          }
        </Card.Body>
      </Card>
    );
  };

  return (
    <Modal
      size="xl"
      title={title || 'Edit text'}
      show={isVisible}
      renderBody={editProjectReportSliceModalBody}
      onHide={onContentChanged}
      closeButtonLoading={closeButtonLoading}
      disabled={closeButtonLoading}
      closeText='Save'
    />
  );
}
