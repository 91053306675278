import { api } from '@rd-web-markets/shared/dist/services/service';
const claimGroupSubRouteService = {
  getAll: (id, subRoute) => api.get("/claim_groups/".concat(id, "/").concat(subRoute)),
  update: function (id, subRoute, body) {
    let subRouteId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    return api.update("/claim_groups/".concat(id, "/").concat(subRoute, "/").concat(subRouteId), body);
  },
  delete: (id, subRoute, subRouteId) => api.delete("/claim_groups/".concat(id, "/").concat(subRoute, "/").concat(subRouteId)),
  create: (id, subRoute, body) => api.create("/claim_groups/".concat(id, "/").concat(subRoute), body),
  putFormData: function (id, subRoute, body) {
    let subRouteId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    return api.putFormData("/claim_groups/".concat(id, "/").concat(subRoute, "/").concat(subRouteId), body);
  },
  postFormData: (id, subRoute, body) => api.postFormData("/claim_groups/".concat(id, "/").concat(subRoute), body)
};
export default claimGroupSubRouteService;