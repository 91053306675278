import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const financialYearsService = {
  update(financialDetailId, financial_year) {
    return fetch("/api/financial_details/".concat(financialDetailId, "/financial_years/").concat(financial_year.id), {
      ...request.put,
      body: JSON.stringify({
        financial_year
      })
    });
  }
};
export default financialYearsService;