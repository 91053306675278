import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const yearFinancialDetailsService = {
  update(projectContractorId, financial_detail) {
    return fetch("/api/project_contractors/".concat(projectContractorId, "/financial_details/").concat(financial_detail.id), {
      ...request.put,
      body: JSON.stringify({
        financial_detail
      })
    });
  },
  upload(yearFinancialDetailId, formData) {
    return fetch("/api/financial_details/".concat(yearFinancialDetailId, "/documents"), {
      ...request.post,
      headers: {
        'Accept': 'application/json, text/plain, */*'
      },
      body: formData
    });
  },
  create(projectContractorId, financial_detail) {
    return fetch("/api/project_contractors/".concat(projectContractorId, "/financial_details"), {
      ...request.post,
      body: JSON.stringify({
        financial_detail
      })
    });
  },
  delete(projectContractorId, financialDetailId) {
    return fetch("/api/project_contractors/".concat(projectContractorId, "/financial_details/").concat(financialDetailId), {
      ...request.delete
    });
  }
};
export default yearFinancialDetailsService;