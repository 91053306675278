import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import claimProjectReportSubRouteService from '@rd-web-markets/shared/dist/services/claim_groups/claim_project_report_sub_route.service';
import React, { useCallback, useEffect, useState } from 'react';
const employeeService = {
  useImportStatus(selectedProjectId) {
    const [data, setData] = useState([]);
    const [importStatus, setImportStatus] = useState({});
    const [intervalId, setIntervalId] = useState(null);
    const getImportStatus = useErrorHandling(useCallback(async () => {
      const response = await this.get(selectedProjectId, 'import_staff_cost_template');
      if (response === null) {
        setImportStatus({
          import_status: 'never_imported'
        });
      } else {
        setImportStatus({
          ...response
        });
      }
      if ((response === null || response === void 0 ? void 0 : response.import_status) !== 'pending') {
        employeeService.useGetStaffCost(setData, selectedProjectId);
      }
      return response;
    }, [selectedProjectId]));
    const importTemplate = useErrorHandling(useCallback(async body => {
      const response = await this.postFormData(selectedProjectId, body);
      setImportStatus({
        ...response
      });
    }, [selectedProjectId]));
    useEffect(() => {
      if (importStatus.import_status === 'pending' && !intervalId) {
        const currentId = setInterval(() => {
          getImportStatus();
        }, 2000);
        setIntervalId(currentId);
      } else if (importStatus.import_status !== 'pending' && !!intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
      return () => {
        clearInterval(intervalId);
      };
    }, [getImportStatus, importStatus.import_status, intervalId, selectedProjectId]);
    useEffect(() => {
      getImportStatus();
    }, [getImportStatus]);
    return [data, setData, importStatus, importTemplate];
  },
  async useGetStaffCost(setData, selectedProjectId) {
    const response = await this.get(selectedProjectId, 'staff_costs');
    setData([...response]);
  },
  useExportTemplate(selectedProjectId) {
    window.open("/api/claim_project_reports/".concat(selectedProjectId, "/export_staff_cost_template"));
  },
  get(selectedProjectId, route) {
    return claimProjectReportSubRouteService.getAll(selectedProjectId, route);
  },
  postFormData(selectedProjectId, body) {
    return claimProjectReportSubRouteService.postFormData(selectedProjectId, 'import_staff_cost_template', body);
  }
};
export default employeeService;