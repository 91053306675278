import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import claimProjectReportSubRouteService from '@rd-web-markets/shared/dist/services/claim_groups/claim_project_report_sub_route.service';
import React, { useCallback, useEffect, useState } from 'react';
const employeeDetailFileService = {
  useGetFiles(selectedProjectId) {
    const [employeeDetailFiles, setEmployeeDetailFiles] = useState([]);
    const getEmployeeDetailFiles = useErrorHandling(useCallback(async () => {
      const response = await this.get(selectedProjectId);
      setEmployeeDetailFiles([...response]);
    }, [selectedProjectId]));
    const uploadEmployeeFile = useErrorHandling(useCallback(async body => {
      await this.postFormData(selectedProjectId, body);
      getEmployeeDetailFiles();
    }, [getEmployeeDetailFiles, selectedProjectId]));
    const deleteEmployeeFile = useErrorHandling(useCallback(async fileId => {
      await this.delete(selectedProjectId, fileId);
      getEmployeeDetailFiles();
    }, [getEmployeeDetailFiles, selectedProjectId]));
    useEffect(() => {
      getEmployeeDetailFiles();
    }, [getEmployeeDetailFiles]);
    return [employeeDetailFiles, uploadEmployeeFile, deleteEmployeeFile];
  },
  get(selectedProjectId) {
    return claimProjectReportSubRouteService.getAll(selectedProjectId, 'employee_detail_files');
  },
  delete(selectedProjectId, fileId) {
    return claimProjectReportSubRouteService.delete(selectedProjectId, 'employee_detail_files', fileId);
  },
  postFormData(selectedProjectId, body) {
    return claimProjectReportSubRouteService.postFormData(selectedProjectId, 'employee_detail_files', body);
  }
};
export default employeeDetailFileService;