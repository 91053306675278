const useUpdateState = (setStateOfData, isNotSaved) => {
  const getValue = (value, isCurrency) => {
    if (value === 'true') {
      return true;
    } else if (value === 'false') {
      return false;
    } else {
      return value;
    }
  };
  const updateState = (event, arrayOfData, key) => {
    arrayOfData[key] = getValue(event.target.value);
    setStateOfData({
      ...arrayOfData
    });
    isNotSaved(true);
  };
  return [updateState];
};
export default useUpdateState;