import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const claimEmployeesService = {
  all(claimId, projectId) {
    return fetch("/api/claims/".concat(claimId, "/claim_project_reports/").concat(projectId, "/claim_employees"), request.get);
  },
  delete(claimEmployee) {
    return fetch("/api/claims/".concat(claimEmployee.claim_id, "/claim_project_reports/").concat(claimEmployee.claim_project_report_id, "/claim_employees/").concat(claimEmployee.id), request.delete);
  },
  update(claimEmployee) {
    return fetch("/api/claims/".concat(claimEmployee.claim_id, "/claim_project_reports/").concat(claimEmployee.claim_project_report_id, "/claim_employees/").concat(claimEmployee.id), {
      ...request.put,
      body: JSON.stringify({
        claim_employee: claimEmployee
      })
    });
  },
  create(claimEmployee) {
    return fetch("/api/claims/".concat(claimEmployee.claim_id, "/claim_project_reports/").concat(claimEmployee.claim_project_report_id, "/claim_employees"), {
      ...request.post,
      body: JSON.stringify({
        claim_employee: claimEmployee
      })
    });
  }
};
export default claimEmployeesService;