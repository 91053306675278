import { useCallback, useState, useEffect } from 'react';
import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
const affiliatedCompaniesService = {
  useAffiliatedCompanies(claimGroupId) {
    const [affiliatedCompanies, setAffiliatedCompanies] = useState(null);
    const fetchAffiliatedCompanies = useErrorHandling(useCallback(async () => {
      const affiliatedCompanies = await affiliatedCompaniesService.all(claimGroupId);
      setAffiliatedCompanies(affiliatedCompanies);
    }, [claimGroupId]));
    useEffect(() => {
      fetchAffiliatedCompanies();
    }, [fetchAffiliatedCompanies]);
    return [affiliatedCompanies, setAffiliatedCompanies];
  },
  useSaveAffiliatedCompany(claimGroupId, setAffiliatedCompanies, setSelectedAffiliatedCompany) {
    return useErrorHandling(useCallback(async (affiliatedCompany, affiliatedCompanies) => {
      if (affiliatedCompany.id) {
        const updatedAffiliatedCompany = await affiliatedCompaniesService.update(claimGroupId, affiliatedCompany);
        const newAffiliatedCompanies = [...affiliatedCompanies];
        const index = newAffiliatedCompanies.findIndex(ac => ac.id === updatedAffiliatedCompany.id);
        newAffiliatedCompanies[index] = updatedAffiliatedCompany;
        setAffiliatedCompanies(newAffiliatedCompanies);
        setSelectedAffiliatedCompany(null);
      } else {
        const newAffiliatedCompany = await affiliatedCompaniesService.create(claimGroupId, affiliatedCompany);
        setAffiliatedCompanies([...affiliatedCompanies, newAffiliatedCompany]);
        setSelectedAffiliatedCompany(null);
      }
    }, [claimGroupId, setAffiliatedCompanies, setSelectedAffiliatedCompany]));
  },
  create(claimGroupId, affiliated_company) {
    return fetch("/api/claim_groups/".concat(claimGroupId, "/affiliated_companies/"), {
      ...request.post,
      body: JSON.stringify({
        affiliated_company
      })
    });
  },
  update(claimGroupId, affiliated_company) {
    return fetch("/api/claim_groups/".concat(claimGroupId, "/affiliated_companies/").concat(affiliated_company.id), {
      ...request.put,
      body: JSON.stringify({
        affiliated_company
      })
    });
  },
  all(claimGroupId) {
    return fetch("/api/claim_groups/".concat(claimGroupId, "/affiliated_companies/"), {
      ...request.get
    });
  },
  delete(claimGroupId, affiliated_company_id) {
    return fetch("/api/claim_groups/".concat(claimGroupId, "/affiliated_companies/").concat(affiliated_company_id), {
      ...request.delete
    });
  }
};
export default affiliatedCompaniesService;