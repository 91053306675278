import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const claimGroupEmployeesService = {
  all(claimGroupId, type) { 
    return fetch(`/api/claim_groups/${claimGroupId}/claim_employees?type=${type}`, request.get);
  }
}

export default claimGroupEmployeesService;
