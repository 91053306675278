import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { api, buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
import { useCallback } from 'react';

const newApi = buildService('/claim_project_reports/{id}/additional_documentations', { only: ['all', 'base'] }, { only: ['postFormData'] })


const useCreateFile = (projectId) => {
  const postFormData = useErrorHandling(
      useCallback(
        async (formData) => {
          await newApi.postFormData(projectId, formData)
        }, [projectId]
      )
    );
  return postFormData
}

const documentsService = {
  all: (claimProjectReportId) => api.get(`/claim_project_reports/${claimProjectReportId}/additional_documentations`),
  create: (claimProjectReportId, formData) => api.postFormData(`/claim_project_reports/${claimProjectReportId}/additional_documentations/`, formData),
  delete: (claimProjectReportId, documentId) => api.delete(`/claim_project_reports/${claimProjectReportId}/additional_documentations/${documentId}`),
  useCreateFile,
}

export default documentsService;
