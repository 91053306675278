export const reasonsForResubmissionTitleAndOptions = [{
  title: 'changes_to_the_applicant_company',
  options: ['changes_of_applicant_company', 'change_in_the_legal_form_of_applicant_company']
}, {
  title: 'changes_to_the_scope_of_the_project',
  options: ['changes_in_term', 'changes_in_form_of_activity', 'changes_in_personnel_or_financial_framework']
}, {
  title: 'changes_to_the_content_of_the_project',
  options: ['changes_in_rd_approach', 'changes_additions_to_work_packages', 'changing_your_own_work_packages_in_a_cooperation_project']
}, {
  title: 'changes_to_the_orders_or_cooperation_partners',
  options: ['changes_of_contractor', 'additional_contractor', 'change_in_the_subject_matter_of_the_order__work', 'additional_cooperation_partner']
}, {
  title: 'other_changes',
  options: ['other_changes']
}];